
import { Button, InputWithLabel, SelectorWithLabel } from 'common/components';
import styles from './notification.module.scss';
import { useEffect, useId, useState } from 'react';
import { SERVER_URL } from 'config';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useFetchBannerList } from '../media/use-media';
import Editor from '@monaco-editor/react';
import classNames from 'classnames';

export const NotificationId = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id === undefined) {
      return;
    }

    fetchNotif(id);
  }, [id]);

  const fetchNotif = async (id: string) => {
    setIsLoading(true);
    const statusesConfig = {
      url: SERVER_URL + `/admin/notices/${id}`,
      method: "get",
      withCredentials: true,
      data: {
        id: 1,
        name: title,
        description
      },
    };

    try {
      const { data } = await axios(statusesConfig);
      setTitle(data.name)
      setDescription(data.description);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const fetchBannerList = useFetchBannerList();

  useEffect(() => {
    fetchBannerList.get();
  }, [])

  const handleEdit = async () => {
    setIsLoading(true);
    const statusesConfig = {
      url: SERVER_URL + `/admin/notices/${id}`,
      method: "put",
      withCredentials: true,
      data: {
        name: title,
        description
      },
    };

    try {
      await axios(statusesConfig);
      navigate('/notification');
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('Are you sure?')) {
      return;
    }

    setIsLoading(true);
    const statusesConfig = {
      url: SERVER_URL + `/admin/notices/${id}`,
      method: "delete",
      withCredentials: true,
      data: {
        name: title,
        description
      },
    };

    try {
      await axios(statusesConfig);
      navigate('/notification');
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <NavLink to="/notification" className="link"><b>Back to list</b></NavLink>

      <div style={{ display: "grid", gridTemplateColumns: "100px 1fr", gap:" 10px" }}>
        <div style={{ width: "100px", height: "100px", border: "1px solid lightgray" }}>
          {title.length !== 0 && <>
            <img
              src={SERVER_URL + "/images/banners/" + title}
              alt="banner"
              style={{ width: "100%", height: "100%", objectFit: "cover", fontSize: 0 }}
            />
          </>}
        </div>
        <SelectorWithLabel
          label="Banner"
          currentValue={title}
          values={Array.from(fetchBannerList.list)}
          onChange={(e) => setTitle(e.currentTarget.value)}
        />
      </div>

      <Editor
        height="300px"
        defaultLanguage="html"
        value={description}
        onChange={newValue => { setDescription(newValue!) }}
        theme="vs-dark"
      />

      <div className={styles.prewview}>
        {title.length !== 0 ? <>
          <img
            src={SERVER_URL + "/images/banners/" + title}
            alt="banner"
            style={{ width: "100%", objectFit: "cover", fontSize: 0 }}
            className={classNames(styles.modalBanner, 'modal-banner')}
          />
        </> : null}
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>

      <Button className={styles.button} onClick={handleEdit}>Edit</Button>
      <Button className={styles.button} onClick={handleDelete}>Delete</Button>
    </div>
  );
}
