import axios from 'axios';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { SERVER_URL } from 'config';
import { Button } from 'common/components';

import styles from './notification.module.scss';

export const NotificationList = () => {
  const navigate = useNavigate();

  const [items, setItems] = useState<any[] | null>(null);

  useEffect(() => {
    fetchNotificationList();
  }, []);

  const fetchNotificationList = async () => {
    const statusesConfig = {
      url: SERVER_URL + "/admin/notices",
      method: "get",
      withCredentials: true,
    };

    try {
      const { data } = await axios(statusesConfig);
      setItems(data.items)
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleCreate = () => {
    navigate('/notification/create'); 
  }

  return (
    <div className={styles.wrapper}>
      <Button className={styles.button} onClick={handleCreate}>Create</Button>

      {items !== null ? items.map(item => (
        <NavLink to={`/notification/${item.id}`} key={item.id} className={styles.linkWrapper}>

          <div style={{ display: "grid", gridTemplateColumns: "100px 1fr", gap:" 10px" }}>
            <div style={{ width: "100px", height: "100px", border: "1px solid lightgray" }}>
              {item.name.length !== 0 && <>
                <img
                  src={SERVER_URL + "/images/banners/" + item.name}
                  alt="banner"
                  style={{ width: "100%", height: "100%", objectFit: "cover", fontSize: 0 }}
                />
              </>}
            </div>
          </div>

          <pre className={styles.linkDescription}>{item.description}</pre>
        </NavLink>
      )) : null}
    </div>
  );
}
