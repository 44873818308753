import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import { Button, Input, InputWithLabel, Loader, Selector, SelectorWithLabel } from "common";
import { fetchGroups, GroupsRequiredFields, useAppDispatch, useAppSelector, deleteGroups, groupsSlice, fetchGroupsDefaultFilters } from "store";
import { CLIENT_URL } from "config";
import { nanoid } from "@reduxjs/toolkit";

type Props = {
  compleatedCallback: (props: {
    fields: GroupsRequiredFields,
    filters: { [key: string]: string }[],
    anti_filters: { [key: string]: string }[],
  }) => void;
};

export function GroupsForm({ compleatedCallback }: Props) {
  const { id }    = useParams();
  const navigate = useNavigate();
  
  const dispatch = useAppDispatch();

  const [name, setName] = useState("");
  const [alias, setAlias] = useState("");
  const [defaultAlias, setDefaultAlias] = useState<string>();
  const [description, setDescription] = useState("");
  const [productId, setProductId] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [languageCode, setLanguageCode] = useState<"ru-RU" | "en-EN">("ru-RU");

  const catalogLink = `${CLIENT_URL}/catalog?_groups=${alias}`;

  const [productIdList, setProductIdList] = useState<number[]>([]);

  const { groups, status } = useAppSelector(state => state.groups);

  useEffect(() => {
    if (!id || groups) return;
    dispatch(fetchGroups())
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!id || !groups) return;
    const item = groups!.find(item => item.alias === id);

    if (!item) return;

    setDefaultAlias(item.alias);
    setName(item.name);
    setAlias(item.alias);
    setDescription(item.description);
    setIsPublished(item.isPublished);
    setLanguageCode(item.languageCode);

    if (item.filters.length > 0) {
      let temp: string[] = [];

      item.filters.forEach(filter => {
        if (filter["include_ids"]) {
          temp = [...temp, ...filter["include_ids"].split(",")];
        }
      })
      setProductIdList(temp as any)
    }
  }, [id, groups]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    changeFiltersType,
    updatePricesFilters,
    updateCheckboxFilters,
    addFullFilter,
    deleteFiltersItem,
    resetFields,
    setFullFilter,
  } = groupsSlice.actions;

  const defaultFilters = useAppSelector(state => state.groups).defaultFilters;
  const { fullFilters, status: fieldsStatus } = useAppSelector(state => state.groups).fields;

  const changeFiltersTypeHandler = ({ id, type }: { id: string, type: "filters" | "anti-filters" }) => {
    dispatch(changeFiltersType({ id, type }));
  };

  const updatePricesFiltersHandler = ({ id, name, title, price }: { id: string, name: string, title: string, price: string }) => {
    dispatch(updatePricesFilters({ id, name, title, price }));
  };

  const updateCheckboxFiltersHandler = ({ id, name, title }: { id: string, name: string, title: string }) => {
    dispatch(updateCheckboxFilters({ id, name, title }));
  };

  const deleteFiltersItemHandler = (id: string) => {
    dispatch(deleteFiltersItem(id));
  };

  const addFullFilterHandler = () => {
    dispatch(addFullFilter());
  };

  const productIdHandler = (event: React.FormEvent<HTMLInputElement>) => {
    let value = event.currentTarget.value;
    value = value.replaceAll("-", "");
    if (isNaN(+value)) return;
    setProductId(value);
  }

  const addProductIdHandler = () => {
    let _productId = +productId;
    if (isNaN(_productId)) return;

    if (productIdList.indexOf(_productId) !== -1) {
      return alert("This ID already exists");
    }

    setProductId("");
    setProductIdList([...productIdList, _productId]);
  };

  const deleteProductIdById = (id: number) => {
    setProductIdList(productIdList.filter(item => item !== id));
  };

  const compleatedCallbackHandler = () => {
    if (name.length === 0 || description.length === 0 || alias.length === 0) {
      return alert("Fill in required fields");
    }

    let _filters: any[] = [];
    let _antiFilters: any[] = [];

    fullFilters.forEach(filter => {
      const _innerFilters: any = {};
      const _innerAntyFilters: any = {};

      for (let i = 0; i < filter.filters.length; i++) {
        let checkedItems = "";
        let prices: any = {};
  
        for (let j = 0; j < filter.filters[i].items.length; j++) {
          if (filter.filters[i].items[j].isChecked === true) {
            checkedItems += "," + filter.filters[i].items[j].title;
          }

          if (filter.filters[i].items[j].forPrices.length !== 0) {
            if (filter.filters[i].items[0] === filter.filters[i].items[j]) {
              prices["min"] = filter.filters[i].items[j].forPrices;
            } else {
              prices["max"] = filter.filters[i].items[j].forPrices;
            }
          }
        }
  
        checkedItems = checkedItems.replace(",", "");
  
        if (checkedItems.length !== 0) {
          if (filter.type === "filters") {
            _innerFilters[filter.filters[i].name] = checkedItems;
          } else if (filter.type === "anti-filters") {
            _innerAntyFilters[filter.filters[i].name] = checkedItems;
          }
        }

        if (prices["min"]) {
          if (filter.type === "filters") {
            _innerFilters["min"] = "" + prices["min"];
          } else if (filter.type === "anti-filters") {
            _innerAntyFilters["min"] = "" + prices["min"];
          }
        }

        if (prices["max"]) {
          if (filter.type === "filters") {
            _innerFilters["max"] = "" + prices["max"];
          } else if (filter.type === "anti-filters") {
            _innerAntyFilters["max"] = "" + prices["max"];
          }
        }
      }

      _filters.push(_innerFilters)
      _antiFilters.push(_innerAntyFilters)
    });

    compleatedCallback({
      fields: {
        name,
        alias,
        defaultAlias: defaultAlias,
        description,
        isPublished,
        languageCode,
        filters: [{ "include_ids": productIdList.join(",") }],
      },
      filters: _filters,
      anti_filters: _antiFilters,
    });
  };

  const deleteHandler = () => {
    if (!window.confirm("Are you sure?")) return;
    dispatch(deleteGroups(alias))
      .then(result => {
        if ((result as any).error) return;
        navigate("/groups");
      });
  }

  useEffect(() => {
    dispatch(fetchGroupsDefaultFilters());
    return () => {
      dispatch(resetFields());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!groups || !id || !defaultFilters.filters.length) return;

    const item = groups!.find(item => item.alias === id);

    const currentPromo: any = item;

    const _includeProductByID: any = [];
    const _excludeProductByID: any = [];
    const _fullFilters: any = [];

    if (currentPromo.filters) {
      for (let i = 0; i < currentPromo.filters.length; i++) {
        const _filters: any = currentPromo.filters[i];
        if (Object.keys(_filters).length === 0) continue;
        if (_filters["include_ids"]) {
          const items = _filters["include_ids"].split(",");
          items.forEach((item: any) => {
            _includeProductByID.push(item);
          })
          continue;
        }

        const filtersKeys = Object.keys(_filters);
  
        const parsedFilters = defaultFilters.filters.map((filter) => ({
          name: filter.name,
          items: filter.items.map((item, pos) => ({
            title: item,
            isChecked: (() => {
              const namePosition = filtersKeys.indexOf(filter.name);
              if (namePosition === -1) { return false }
              const selectedItems = _filters[filtersKeys[namePosition]].split(",");
              return selectedItems.indexOf(item) !== -1;
            })(),
            forPrices: (() => {
              if (filter.name !== "prices" || filtersKeys.indexOf("prices") === -1) return "";
              const selectedPrices = _filters["prices"].split(",");
              if (selectedPrices.length === 2) {
                return selectedPrices[pos] === "0" ? "" : selectedPrices[pos];
              } else {
                if (pos === 0) return selectedPrices[pos] === "0" ? "" : selectedPrices[pos];
                return "";
              }
            })()
          })),
        }));

        const pricesIndex = parsedFilters.findIndex(item => item.name === "prices");
        parsedFilters[pricesIndex].items[0] = {
          ...parsedFilters[pricesIndex].items[0],
          forPrices: _filters["min"] || "",
        }
        parsedFilters[pricesIndex].items[1] = {
          ...parsedFilters[pricesIndex].items[1],
          forPrices: _filters["max"] || "",
        }

        _fullFilters.push({
          id: nanoid(),
          filters: parsedFilters,
          type: "filters",
        });
      }
    }

    if ((currentPromo as any).antiFilters) {
      for (let i = 0; i < (currentPromo as any).antiFilters.length; i++) {
        const _antiFilters = (currentPromo as any).antiFilters[i];
        if (Object.keys(_antiFilters).length === 0) continue;
        if (_antiFilters["include_ids"]) {
          const items = _antiFilters["include_ids"].split(",");
          items.forEach((item: any) => {
            _excludeProductByID.push(item);
          })
          continue;
        }

        const filtersKeys = Object.keys(_antiFilters);
        
        const parsedFilters = defaultFilters.filters.map((filter) => ({
          name: filter.name,
          items: filter.items.map((item, pos) => ({
            title: item,
            isChecked: (() => {
              const namePosition = filtersKeys.indexOf(filter.name);
              if (namePosition === -1) { return false }
              const selectedItems = _antiFilters[filtersKeys[namePosition]].split(",");
              return selectedItems.indexOf(item) !== -1;
            })(),
            forPrices: ""
          })),
        }));
  
        _fullFilters.push({
          id: nanoid(),
          filters: parsedFilters,
          type: "anti-filters",
        });
      }
    }

    dispatch(setFullFilter({ fullFilters: _fullFilters }))
  }, [groups, defaultFilters]);

  if (status.isLoading || defaultFilters.filters.length === 0) {
    return <Loader />;
  }

  return (
    <div>
      <div><b><NavLink to={"/groups"}>Back</NavLink></b></div>
      <br />

      {fullFilters.map(filters => (
        <div className="create-or-edit__filters" key={filters.id}>
          <div className="create-or-edit__filters-header">
            <Selector
              currentValue={filters.type}
              values={["filters", "anti-filters"]}
              onChange={e => { changeFiltersTypeHandler({
                id: filters.id,
                type: e.currentTarget.value as any, 
              }) }}
            />
            <div className="create-or-edit__filters-header-drop" onClick={e => deleteFiltersItemHandler(filters.id)}>Drop</div>
          </div>
          <div className="create-or-edit__filters-items">
            {filters.filters.map((filter, pos) => (
              <div key={filter.name} className="create-or-edit__filters-items-inner">
                <div className="create-or-edit__filters-items-name">{filter.name}</div>
                <div className="create-or-edit__filters-items-items">
                  {filter.name === "prices" && <>
                    <Input
                      type="text"
                      placeholder={filters.filters[pos].items[0].title}
                      value={"" + filters.filters[pos].items[0].forPrices}
                      onChange={e => { if(!isNaN(+e.currentTarget.value)) updatePricesFiltersHandler({
                        price: e.currentTarget.value,
                        id: filters.id, name: filter.name, title: filters.filters[pos].items[0].title,
                      }) }}
                    />
                    <Input
                      type="text"
                      placeholder={filters.filters[pos].items[1].title}
                      value={"" + filters.filters[pos].items[1].forPrices}
                      onChange={e => { if(!isNaN(+e.currentTarget.value)) updatePricesFiltersHandler({
                        price: e.currentTarget.value,
                        id: filters.id, name: filter.name, title: filters.filters[pos].items[1].title,
                      }) }}
                    />
                  </>}              
                  {filter.name !== "prices" && filter.items.map(items => {
                    return <InputWithLabel
                      key={items.title}
                      type="checkbox"
                      label={items.title}
                      checked={items.isChecked}
                      onChange={() => { updateCheckboxFiltersHandler({ id: filters.id, name: filter.name, title: items.title }) }}
                    />
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <Button onClick={addFullFilterHandler}>+</Button>
      <br />





      <InputWithLabel type="string" label="Name *" onChange={e => setName(e.currentTarget.value)} value={name} />
      <InputWithLabel type="string" label="Alias *" onChange={e => setAlias(e.currentTarget.value)} value={alias} />
      <InputWithLabel type="string" label="Description *" onChange={e => setDescription(e.currentTarget.value)} value={description} />
      <SelectorWithLabel
        currentValue={languageCode}
        label="Currency"
        values={["ru-RU", "en-EN"]}
        onChange={e => setLanguageCode(languageCode === "ru-RU" ? "en-EN" : "ru-RU")}
      />
      <br />
      <InputWithLabel type="string" label="Product ID" onChange={productIdHandler} value={productId} />
      <Button onClick={addProductIdHandler}>Add</Button>
      <br />
      <ul>
        <li><b>Products ID: *</b></li>
        {productIdList.length === 0 ? <li>List is empty</li> : <>
          {productIdList.map(item =>
            <li key={item} style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{item}</div>
              <div
                style={{ fontWeight: "bold", cursor: "pointer" }}
                onClick={() => deleteProductIdById(item)}
              >delete</div>
            </li>
          )}
        </>}
      </ul>
      <br />
      <InputWithLabel type="checkbox" label="Is published" checked={isPublished} onChange={() => setIsPublished(!isPublished)} />
      <Button onClick={compleatedCallbackHandler}>{id ? "Edit" : "Create"}</Button>
      {id && <Button onClick={deleteHandler}>Delete</Button>}
      <div style={{ color: "red" }}>{status.error?.message}</div>
      <br />
      <InputWithLabel
        type="string"
        label="Сlick on the link to copy"
        value={catalogLink}
        onChange={() => 0}
        onClick={() => navigator.clipboard.writeText(catalogLink)}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
}